import React from 'react';
import Content, {HTMLContent} from '../components/Content'
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import BreadCrumb from "../components/BreadCrumb";

export const TermsOfUseTemplate = ({content, contentComponent}) => {
  const PostContent = contentComponent || Content;
  const links = [
    {label: 'Home', value: '/'}
  ];
  return <div className={'terms-of-use container'}>
    <BreadCrumb links={links} currentPage={'Terms of Use'}/>
    <h1 className={'title'}>Terms of Use</h1>
    <PostContent content={content} className={'content'}/>
  </div>
};

const TermsOfUse = ({data}) => {
  const {markdown: post} = data;
  return <Layout>
    <TermsOfUseTemplate
      content={post.html}
      contentComponent={HTMLContent}
    />
  </Layout>;
};

export default TermsOfUse;

export const pageQuery = graphql`
  query TermsOfUseTemplate {
   markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "terms-of-use" } }
    ) {
      id
      html
    }
  }
`
